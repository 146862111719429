<!-- 系统设置-基础设置-成绩等级设置 -->
<template>
    <div class="exam-setting-wrap">
        <div class="exam-setting-header">
            <div class="title">
                <span>成绩等级设置</span>
                <el-button @click="dialogOpen" type="primary">查看方式</el-button>
            </div>
            <div>
                <el-button v-if="!isEdit" type="primary" @click="isEdit = true" v-hasPermi="['level:edit']">编辑
                </el-button>
                <template v-else>
                    <el-button type="primary" @click="add">添加</el-button>
                    <el-button type="enquiry" @click="cancel">取消</el-button>
                    <el-button type="primary" @click="save">保存</el-button>
                </template>
            </div>
        </div>
        <el-table
            ref="table"
            v-loading="loadingTable"
            :data="list"
            style="width: 100%"
            :header-cell-style="{background:backgroundHeader,color:headerColor}"
            :cell-style="{color:headerColor}"
            :height="tableConfig.height + 10"
            stripe
        >
            <el-table-column prop="levelName" label="等级名称" align="center">
                <template slot-scope="scope">
                    <el-input placeholder="请输入" :readonly="!isEdit" v-model="scope.row.levelName"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="lowPercentage" label="最低分" align="center">
                <template slot="header" slot-scope="scope">
                    <table-header :item="{ label: '最低分', labelDescription: '（百分比）' }" />
                </template>
                <template slot-scope="scope">
                    <span v-if="!isEdit">{{ scope.row.lowPercentage }}</span>
                    <el-input-number v-else :min="0" :max="100" :controls="false" placeholder="请输入"
                                     v-model="scope.row.lowPercentage"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="highPercentage" label="最高分" align="center">
                <template slot="header" slot-scope="scope">
                    <table-header :item="{ label: '最高分', labelDescription: '（百分比）' }" />
                </template>
                <template slot-scope="scope">
                    <span v-if="!isEdit">{{ scope.row.highPercentage }}</span>
                    <el-input-number v-else :min="0" :max="100" :controls="false" placeholder="请输入"
                                     v-model="scope.row.highPercentage" @change="handleInput"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" v-if="isEdit">
                <template slot-scope="scope">
                    <el-button type="text" class="danger" @click="dl(scope.$index)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>


        <dialog-wrapper :dialogObj="dialogData" @handleClose="dialogClose">
            <el-radio v-model="type" label="1">分数</el-radio>
            <el-radio v-model="type" label="2">等级</el-radio>

            <div style="text-align: right;margin-top: 15px">
                <el-button @click="dialogClose" type="enquiry">取消</el-button>
                <el-button @click="setting" type="primary">确定</el-button>
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import {DialogWrapper} from 'common-local'
import onResize from "@/mixins/onResize";
import TableHeader from "@/components/scrollWrapper/Sub/TableData/TableHeader"

export default {
    name: "SystemExamSettingScrollWrapper",
    mixins: [onResize],
    components: {DialogWrapper,TableHeader},
    data() {
        return {
            loadingTable: true,
            schoolId: '',
            list: [],
            isEdit: false,
            dialogData: {
                dialogVisible: false,
                title: "成绩等级查看方式",
                width: "640px"
            },
            type: '',
            backgroundHeader: '#fafbfc',
            headerColor: '#595959',
            tableConfig: {
                height: null
            }
        }
    },
    created() {
        this.schoolId = this.$store.state.schoolId
        this.getList()
    },
    methods: {
        getList() {
            this.loadingTable = true;
            this._fet('/school/schoolExamLevelSetting/getRankBySchoolId', {
                schoolId: this.schoolId,
                productId: 'cloud_campus'
            }).then((res) => {
                this.handleRes(res, () => {
                    this.list = res.data.data
                })
                this.loadingTable = false;
            })
        },
        add() {
            this.list.push({
                levelName: '',
                levelType: '',
                lowPercentage: '',
                highPercentage: '',
            })
        },
        dl(index) {
            this.list.splice(index, 1)
        },
        cancel() {
            this.isEdit = false;
            this.getList()
        },
        handleInput(data) {
            if (data) {
                this.list.sort(this.comput('highPercentage'))
            }
        },
        comput(x) {
            return function (a, b) {
                var m = a[x],
                    n = b[x];
                return n - m;
            }
        },
        save() {
            if (this.list.length === 0) {
                return this.$message.warning('成绩等级设置不能为空')
            }
            let find = this.list.find(i => i.levelName === '' || i.lowPercentage === '' || i.highPercentage === '')
            if (find) {
                return this.$message.warning('缺少必填项')
            }
            for (let i = 0; i < this.list.length - 1; i++) {
                if (Number(this.list[i].lowPercentage) < Number(this.list[i + 1].highPercentage)) {
                    return this.$message.warning('最高分、最低分区间重复')
                }
                if (Number(this.list[i].lowPercentage) > Number(this.list[i + 1].highPercentage)) {
                    return this.$message.warning('等级区间不连续')
                }
            }
            this._fet('/school/schoolExamLevelSetting/saveRank', {
                list: this.list,
                schoolId: this.schoolId,
                examId: ''
            }).then((res) => {
                this.handleRes(res, () => {
                    this.$message.success('保存成功！')
                    this.isEdit = false
                    this.getList()
                })
            })
        },
        handleChange(data) {
            // console.log(data)
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
        dialogOpen() {
            this._fet('/school/schoolOrgan/getGradeSendType', {
                id: this.schoolId
            }).then((res) => {
                this.type = res.data.data.displayType || '1'
                this.dialogData.dialogVisible = true
            })
        },
        dialogClose() {
            this.dialogData.dialogVisible = false
        },
        setting() {
            if (this.type === '') {
                return this.$message.warning('请选择成绩等级查看方式')
            }
            this._fet('/school/schoolOrgan/modifyGradeSendType', {
                id: this.schoolId,
                displayType: this.type
            }).then((res) => {
                if (res.data.code === '200') {
                    this.$message.success('设置成功')
                    this.dialogClose()
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.exam-setting-wrap {
    margin: 10px 10px 0 0;
    height: calc(100vh - 143px);

    .exam-setting-header {
        border-radius: 4px;
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        background-color: #fff;

        .title {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            span {
                margin-right: 10px;
            }
        }
    }
}

.el-table {
    border-radius: 4px;
    overflow: hidden;

    ::v-deep .el-table__cell {
        border: none !important;
    }

    .el-table__body .el-table__row.hover-row td {
        background-color: #EBEFF5 !important;
    }

    ::v-deep .el-input__inner {
        border-width: 0;
        padding: 0 15px;
        text-align: center;
        background-color: transparent;
        font-size: 14px;
    }
}
</style>
