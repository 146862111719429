<!-- 系统设置-基础设置-查看成绩方式 -->
<template>
    <el-card class="box-card">
        <span class="box-title">成绩等级查看方式</span>
        <el-radio v-model="type" label="1">分数</el-radio>
        <el-radio v-model="type" label="2">等级</el-radio>
        <div class="box-btn">
            <xk-button type="primary" @click="setting"  v-hasPermi="['systemExamView:save']">设置</xk-button>
        </div>
    </el-card>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: "SystemViewScrollWrapper",
    data() {
        return {
            type: ''
        }
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this._fet('/school/schoolOrgan/getGradeSendType', {
                id: this.schoolId
            }).then((res) => {
                this.type = res.data.data.displayType || '1'
            })
        },
        setting() {
            if (this.type === '') {
                return this.$message.warning('请选择成绩等级查看方式')
            }
            this._fet('/school/schoolOrgan/modifyGradeSendType', {
                id: this.schoolId,
                displayType: this.type
            }).then((res) => {
                if (res.data.code === '200') {
                    this.$message.success('设置成功')
                }
            })
        }
    }
}
</script>

<style scoped>
.box-card {
    margin: 10px 0 0 10px;
    width: 400px;
    border-radius: 6px;
}

.box-title {
    margin-right: 30px;
    font-size: 14px;
}

.box-btn {
    margin-top: 40px;
    text-align: right;
}
</style>
