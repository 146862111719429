<!-- 系统设置-基础设置-数据字典 -->
<template>
    <div class="content-main">
        <div v-if="!errorShow">
            <loading
                v-if="loadingShow"
                :loadingShow="loadingShow"
            ></loading>
            <div
                v-else
                style="display: flex"
            >
                <div class="dict-left-content">
                    <div
                        class="dict-title"
                        :style="{color: headerColor}"
                    >
                        字典类别
                    </div>
                    <el-input
                        v-model="filterDict"
                        placeholder="输入字典类别"
                        @clear="handleClear"
                        @input="handleChange"
                        class="filter-input"
                        clearable
                    />
                    <div
                        v-for="(item, index) in dictList"
                        :key="item.id"
                        class="dict-item"
                        :class="{active: listQuery.dictKey === item.value}"
                        :style="{color: headerColor}"
                        @click="changeDict(item.value, index)"
                    >
                        {{ item.label }}
                    </div>
                </div>
                <div class="dict-right-content">
                    <!-- <div class="dict-title" :style="{'color': headerColor}">字典项</div> -->
                    <div class="fliter-wrapper">
                        <el-button
                            type="primary"
                            v-show="listQuery.dictKey !== 'courseSelection'"
                            @click="add"
                            v-hasPermi="['dict:add']"
                            >新增</el-button
                        >
                        <el-input
                            v-if="listQuery.dictKey == 'teachingSubjects'"
                            style="width: 200px; margin-left: 10px"
                            v-model="subjectsName"
                            placeholder="请输入教学科目名称"
                            @input="handlerSubjectsNameChange"
                        ></el-input>
                    </div>
                    <table-data
                        v-loading="loadingTable"
                        ref="table"
                        id="table"
                        :key="keyValue"
                        :config="table_config"
                        :tableData="table_data"
                        :backgroundHeader="backgroundHeader"
                        :headerColor="headerColor"
                        @switchHandler="switchHandler"
                    >
                        <template v-slot:enablingStatus="slotData">
                            <template>
                                <el-switch
                                    v-model="slotData.data.state"
                                    :disabled="!hasPermission(['dict:edit'])"
                                    :active-value="1"
                                    :inactive-value="0"
                                    @change="stateChange(slotData.data)"
                                ></el-switch>
                            </template>
                        </template>
                        <template v-slot:operation="slotData">
                            <!-- 选课组合 -->
                            <template
                                v-if="listQuery.dictKey === 'courseSelection'"
                            >
                                <el-switch
                                    v-model="slotData.data.state"
                                    :disabled="!hasPermission(['dict:edit'])"
                                    :active-value="1"
                                    :inactive-value="0"
                                    @change="stateChange(slotData.data)"
                                ></el-switch>
                            </template>
                            <!-- 基础教学科目 -->
                            <template
                                v-else-if="
                                    listQuery.dictKey === 'teachingSubjects' &&
                                    baseSubjects.includes(slotData.data.name)
                                "
                            >
                                <el-button
                                    type="success"
                                    size="mini"
                                    @click="edit(slotData.data)"
                                    v-hasPermi="['dict:edit']"
                                    >编辑</el-button
                                >
                            </template>
                            <!-- 考试类型 云校/云阅/智慧课堂不显示编辑删除操作 -->
                            <template v-else-if="
                                listQuery.dictKey === 'examType' &&
                                slotData.data.isDefault == 1"
                            >
                                <el-button
                                    type="text"
                                    @click="edit(slotData.data,1)"
                                    v-hasPermi="['dict:edit']"
                                >编辑</el-button>
                            </template>
                            <!-- 其他 -->
                            <template v-else>
                                <el-button
                                    type="text"
                                    @click="edit(slotData.data)"
                                    v-hasPermi="['dict:edit']"
                                >编辑</el-button>
                                <el-button
                                    type="text"
                                    @click="del(slotData.data)"
                                    v-hasPermi="['dict:delete']"
                                >删除</el-button>
                            </template>
                        </template>
                    </table-data>
                    <Pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList"
                    />
                </div>
            </div>
            <dialog-wrapper
                :dialogObj="dialogObj"
                @handleClose="handleClose"
            >
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-position="right"
                    label-width="94px"
                    class="common-form"
                >
                    <el-form-item
                        label="字典名称"
                        prop="name"
                    >
                        <el-input
                            v-model="ruleForm.name"
                            placeholder="请输入字典名称"
                            :disabled="
                                (listQuery.dictKey === 'teachingSubjects' &&
                                baseSubjects.includes(ruleForm.name)) || (listQuery.dictKey === 'examType' &&
                                ruleForm.isDefault == 1)
                            "
                            class="input-width-medium"
							maxlength=10
							show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="字典排序"
                        prop="sort"
                    >
                        <el-input
                            :min="0"
                            type="number"
                            v-model.number="ruleForm.sort"
                            placeholder="请输入排序号"
                            class="input-width-medium"
                        />
                    </el-form-item>
                    <el-form-item
                        label="录屏有效期"
                        prop="remark"
                        v-if="listQuery.dictKey=='meetingType'"
                    >
                        <el-select clearable  class="input-width-medium" v-model="ruleForm.remark" placeholder="请选择录屏有效期">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>

                    </el-form-item>
                    <el-form-item
                        :class="(listQuery.dictKey == 'teachingSubjects')?'remark_class':''"
                        label="字典描述"
                        prop="remark"
                        v-else
                        >
                        <el-input
                            type="textarea"
                            rows="2"
                            v-model="ruleForm.remark"
                            placeholder="请输入字典描述"
                            class="input-width-medium"
							maxlength=50
							show-word-limit
                        ></el-input>
                        <el-tooltip v-if="listQuery.dictKey == 'teachingSubjects'" popper-class="tooltip-popper" class="first_enter" content="当字典描述为“1”表示正课,“2”表示早读,“3”表示晚自习,“4”表示阅读" placement="top-start" :offset="30">
                            <i class="el-icon-question label-description"></i>
                        </el-tooltip>
                    </el-form-item>

                    <div :style="{'text-align': 'right'}">
                        <el-button
                            type="enquiry"
                            @click="handleClose"
                        >取消</el-button>
                        <el-button
                            type="primary"
                            @click="submit"
                        >确定</el-button>
                    </div>
                </el-form>
            </dialog-wrapper>
        </div>
        <custom-error
            v-else
            :errorShow="errorShow"
        ></custom-error>
    </div>
</template>

<script>
import {
    // 业务组件
    FilterData,
    Pagination,
    // 功能组件
    Error as CustomError,
    Loading,
    DialogWrapper,
    // 工具函数
    throttle,
    debounce,
} from "common-local";
// Ajax 请求
import TableData from "./Sub/TableData"
import {SystemModel} from "@/models/System.js";
import {hasPermission} from "@/libs/utils";
import {mapState} from "vuex";
import onResize from "@/mixins/onResize"
export default {
    mixins: [onResize],
    name: "systemDictScrollWrapper",
    components: {
        FilterData,
        TableData,
        Pagination,
        DialogWrapper,
        CustomError,
        Loading,
    },
    data() {
        const validateSort = (rule, value, callback) => {
            if (value === '' || value === null || value === undefined) {
                callback(new Error('排序值不能为空'));
            } else if (typeof value === 'number' && (value < 0 || value > 1000 || !Number.isInteger(value))) {
                callback(new Error('请输入 0 到 1000 之间的正整数'));
            } else if (typeof value === 'string' && !/^[a-zA-Z]+$/.test(value)) {
                callback(new Error('请输入有效的字符串'));
            } else {
                callback();
            }
        };
        return {
            options:[
                {
                    label:'1个月',
                    value:'1个月'
                },
                {
                    label:'3个月',
                    value:'3个月'
                },
                {
                    label:'6个月',
                    value:'6个月'
                },
                {
                    label:'1年',
                    value:'1年'
                },
                {
                    label:'3年',
                    value:'3年'
                },
            ],
            subjectsName: "",
            baseSubjects: [
                "化学",
                "历史",
                "数学",
                "语文",
                "物理",
                "生物",
                "政治",
                "英语",
                "地理",
            ],
            // 页面状态
            filterDict: "",
            dictList: [],
            ruleForm: {
                name: "",
                id: "",
                sort: "",
                dictKey: "",
                remark: "",
                schoolId: "",
                isDefault:''
            },
            hasPermission,
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入字典名称",
                        trigger: "blur",
                    },
                ],
                dictKey: [
                    {
                        required: true,
                        message: "请选择字典类别",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        validator: validateSort,
                        trigger: "blur",
                    },
                ],
            },
            keyValue:1,
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "字典名称",
                        prop: "name",
						align: 'center'
                    },
                    {
                        label: "字典排序",
                        prop: "sort",
                        align: 'center'
                    },
                    {
                        label: "字典描述",
                        // labelDescription: "当字典描述为“1”时表示正课",
                        prop: "remark",
						align: 'center',
                        className: 'tooltip'
                    },
                    {
                        label: "修改时间",
                        prop: "newUpdateTime",
                        align: 'center'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                height: "",
            },
            // 表格数据
            table_data: [],
            // 分页器
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                dictKey: "",
                schoolId: "",
                name: "",
            },

            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,

            // 表头
            backgroundHeader: "#FAFBFC",
            // 表头字体
            headerColor: "",
            template_info: [],
            // 按钮字体颜色
            btnColor: "#ffffff",
            // 弹出框配置
            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            types: [],
        };
    },
    computed: {
        ...mapState({
            templateInfo: (state) => state.templateInfo,
        }),
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.table.$refs.multipleTable.doLayout();
        });
    },
    created() {
        this.init();
    },
    mounted() {
    },
    methods: {
        init() {
            this.listQuery.schoolId = this.$store.state.schoolId;
            this.ruleForm.schoolId = this.$store.state.schoolId;
            this.getTypeList();
        },
        handlerSubjectsNameChange: debounce(
            function () {
                this.listQuery.name = this.subjectsName;
                this.listQuery.pageNum = 1;
                this.getList();
            },
            200,
            true,
        ),
        changeDict(value, index) {
            console.log("[changeDict]value:", value, "index:", index)
            this.handleMoralDisposeTypeGroupTableConfig(value)
            this.table_config.thead.forEach((item) => {
                if (item.slotName === "operation") {
                    item.label =
                        value === "courseSelection" ? "启用状态" : "操作";
                    item.className=
                        value === "courseSelection" ? "" : "text-spacing-reduction";
                }
            });
            if( value== 'examType'){
                if(this.table_config.thead.findIndex(obj => {return obj.slotName == 'enablingStatus'}) == -1){
                    this.table_config.thead.splice(3,0,{
                        label: "启用状态",
                        type: "slot",
                        slotName: "enablingStatus",
                        align: 'center',
                    })
                }
            }else{
                let theadIndex = this.table_config.thead.findIndex(obj => {return obj.slotName == 'enablingStatus'})
                if(theadIndex != -1){
                    this.table_config.thead.splice(theadIndex,1)
                }
            }
            this.ruleForm.dictKey = value;
            this.listQuery.dictKey = value;
            this.table_config.thead.forEach(iv=>{
                if(iv.prop=='remark'){
                    if(this.listQuery.dictKey == 'teachingSubjects'){
                        this.$set(iv,'labelDescription','当字典描述为“1”表示正课,“2”表示早读,“3”表示晚自习,“4”表示阅读')
                    }else{
                        delete iv.labelDescription
                    }
                }
            })
            this.listQuery.name = "";
            this.listQuery.pageNum = 1;
            this.getList();
            this.keyValue += 1;
        },
        handleMoralDisposeTypeGroupTableConfig(dictKey) {
            // 德育处理方式字典增加是否展示列及ruleForm增加type
            // 其他字典删除增加的是否展示列及ruleForm中增加的type
            if (dictKey === 'moralDisposeTypeGroup') {
                // 没有是否展示列时增加是否展示列
                let typeTheadItemIndex = this.table_config.thead.findIndex(e => e.prop === "type")
                if (typeTheadItemIndex == -1) {
                    let typeTheadItem = {
                        label: "是否展示",
                        type: "switch",
                        prop: "type",
                        activeValue: 1,
                        inactiveValue: 0
                    }
                    let timeTheadItemIndex = this.table_config.thead.findIndex(e => e.prop === "newUpdateTime")
                    if (timeTheadItemIndex > -1) {
                        this.table_config.thead.splice(timeTheadItemIndex + 1, 0, typeTheadItem)
                    } else {
                        let operationTheadItemIndex = this.table_config.thead.findIndex(e => e.slotName === "operation")
                        if (operationTheadItemIndex > -1) {
                            this.table_config.thead.splice(operationTheadItemIndex, 0, typeTheadItem)
                        } else {
                            this.table_config.thead.push(typeTheadItem)
                        }
                    }
                }
                // ruleForm增加type
                this.$set(this.ruleForm, "type", 0)
            } else {
                // 删除是否展示列
                let typeTheadItemIndex = this.table_config.thead.findIndex(e => e.prop === "type")
                if (typeTheadItemIndex > -1) {
                    this.table_config.thead.splice(typeTheadItemIndex, 1)
                }
                // ruleForm删除type
                if (this.ruleForm.hasOwnProperty("type")) {
                    delete this.ruleForm.type
                }
            }
        },
        switchHandler: debounce(function (rowData, theadItem) {
            console.log("[switchHandler]rowData:", rowData, "theadItem:", theadItem)
            if (theadItem.prop === "type") { // 是否展示
                this.loadingTable = true
                let ruleForm = {}
                for (let key in this.ruleForm) {
                    this.$set(ruleForm, key, rowData[key])
                }
                console.log("[switchHandler]ruleForm:", ruleForm)
                this._fet("/school/schoolDictValue/save", ruleForm).then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success("修改成功");
                        this.getList()
                    });
                });
            }
        }, 200, true),
        stateChange: throttle(function (value) {
            console.log(value,'value')
            if(this.listQuery.dictKey === 'examType'){
                let msg = value.state == 1?'是否确认启用？':'停用后则无法选择使用并且家长端不展示，是否确认停用？'
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 确认
                    this._fet("/school/schoolDictValue/updateState", {
                        schoolId: this.listQuery.schoolId,
                        state: value.state,
                        id: value.id,
                    }).then((res) => {
                        // this.handleRes(res, () => {
                        //     this.$message.success("启用状态修改成功");
                        //     this.getList();
                        // });
                        if (res.data.code === "200") {
                            this.$message.success("启用状态修改成功");
                            this.getList();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                            this.getList();
                        }
                    });
                }).catch(() => {
                    // 取消
                    this.getList();
                });
            }else{
                this._fet("/school/schoolDictValue/updateState", {
                    schoolId: this.listQuery.schoolId,
                    state: value.state,
                    id: value.id,
                }).then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success("启用状态修改成功");
                        this.getList();
                    });
                });
            }

        }, 1000),
        handleChange: throttle(function (value) {
            this.dictList = this.types.filter((item) => {
                return item.label.includes(value);
            });
        }, 1000),
        handleClear() {
            this.dictList = this.types;
        },
        initTabColor() {
            this.template_info = this.templateInfo;
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    this.headerColor = p.textColor;
                }
            });
        },
        // 编辑
        edit: throttle(function (data,type) {
            Object.keys(this.ruleForm).forEach((key) => {
                this.ruleForm[key] = data[key];
            });
            this.dialogObj.title = "编辑字典";
            this.dialogObj.dialogVisible = true;
        }, 1000),
        del(data) {
            const systemModel = new SystemModel();
            this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    systemModel
                        .delDict([
                            {
                                id: data.id,
                                name: data.name,
                                dictKey: data.dictKey,
                            },
                        ])
                        .then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success("删除成功!");
                                this.getList();
                            });
                        });
                })
                .catch(() => {});
        },
        add() {
            this.dialogObj.title = "添加字典";
            this.dialogObj.dialogVisible = true;
        },
        submit() {
            this.$refs.ruleForm.validate((val) => {
                if (val) {
                    if (parseInt(this.ruleForm.sort) <= 0) {
                        this.$message.warning("字典排序不得小于1");
                        return;
                    }
                    if (parseInt(this.ruleForm.sort) > 2147483647) {
                        this.$message.warning("字典排序超出限制，请重新输入");
                        return;
                    }
                    if (this.dialogObj.title === "添加字典") {
                        this._fet(
                            "/school/schoolDictValue/save",
                            this.ruleForm,
                        ).then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success(
                                    `${this.dialogObj.title}成功`,
                                );
                                this.handleClose();
                                this.getList();
                            });
                        });
                    } else {
                        this._fet(
                            "/school/schoolDictValue/save",
                            this.ruleForm,
                        ).then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success(
                                    `${this.dialogObj.title}成功`,
                                );
                                this.handleClose();
                                this.getList();
                            });
                        });
                    }
                }
            });
        },
        // table 分页器
        getList() {
            this.loadingTable = true;
            this._fet("/school/schoolDictValue/list", this.listQuery).then(
                (res) => {
                    this.handleRes(res, () => {
                        res.data.data.list.forEach((item) => {
                            item['newCreateTime'] = item['createTime'].substring(0,16)
                            item['newUpdateTime'] =  item['updateTime'].substring(0,16)
                        })

                        this.table_data = res.data.data.list;
                        this.total = res.data.data.totalCount;
                        this.loadingTable = false;
                        this.$nextTick(() => {
                            this.$refs.table.$refs.multipleTable.doLayout();
                        });
                    });
                },
            );
        },
        async getTypeList() {
            let query = this.$route.query.dictKey;
            this._fet("/school/schoolDictKey/listByCondition", {
                schoolId: this.listQuery.schoolId,
            }).then((res) => {
                this.handleRes(res, () => {
                    if (res.data.data.length > 0) {
                        this.listQuery.dictKey =
                            query || res.data.data[0].dictKey;
                        this.ruleForm.dictKey =
                            query || res.data.data[0].dictKey;
                        this.handleMoralDisposeTypeGroupTableConfig(this.listQuery.dictKey)
                        this.table_config.thead.forEach((item) => {
                            if (item.slotName === "operation") {
                                item.label =
                                    this.ruleForm.dictKey === "courseSelection"
                                        ? "启用状态"
                                        : "操作";
                            }
                        });
                        this.types = res.data.data.map((it) => ({
                            label: it.name,
                            value: it.dictKey,
                        }));
                        this.dictList = this.types;
                        if(this.$route.params.type == 'examType'){
                            for (let index = 0; index < this.dictList.length; index++) {
                                if(this.$route.params.type == this.dictList[index].value){
                                    this.changeDict(this.dictList[index].value,index)
                                }
                            }
                        }
                        console.log('this.dictList655',this.dictList);
                        this.getList();
                    }
                });
            });
        },
        handleClose() {
            this.ruleForm.name = "";
            this.ruleForm.id = "";
            this.ruleForm.sort = "";
            this.ruleForm.remark = "";
            this.$refs.ruleForm.resetFields();
            this.dialogObj.dialogVisible = false;
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        /**
         * @Description: 改变规则
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-07-31 11:13:55
         */
        changeRules(){
            if(this.listQuery.dictKey=='meetingType'){
                this.$set(this.rules, 'remark',[
                        {
                            required: true,
                            message: "请选择录屏有效期",
                            trigger: "blur",
                        },
                    ])
            }else{
                this.$set(this.rules, 'remark',undefined)
            }
            console.log(this.rules,'rules')
        },
        /**
         * @Description: 改变表格的展示数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-07-31 11:44:16
         */
        changeTableConfig(){
            if(this.listQuery.dictKey=='meetingType'){
                let objIndex=this.table_config.thead.findIndex(item=>{
                    return item.prop=='remark'
                })
                this.table_config.thead[objIndex].label="录屏有效期"
            }else{
                let objIndex=this.table_config.thead.findIndex(item=>{
                    return item.prop=='remark'
                })
                this.table_config.thead[objIndex].label="字典描述"
            }
        },
    },
    watch: {
        templateInfo: {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true,
        },
        'listQuery.dictKey':{
            handler(){
                this.changeRules()
                this.changeTableConfig()
            },
            deep: true,
        },
        $route: {
            handler (oldVal,newVal) {
                console.log(oldVal,newVal,'hjm')
                if(oldVal == 'analysis'){

                }
            }
        }
    },
};
</script>
<style lang="scss" scoped>
::v-deep .common-form .el-form-item__label{
    padding: 0 11px 0 0 !important;
}
.dict-title {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    color: #333333;
    border-bottom: 1px solid #ebeef5;
}

.fliter-wrapper {
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
}

.dict-left-content {
    margin-right: 10px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0 10px;
    width: 200px;
    color: #303133;
    height: calc(100vh - 142px);

    .dict-item {
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
    }

    .filter-input {
        margin-bottom: 5px;
    }
}

.dict-right-content {
    width: calc(100% - 230px);
    .add-btn {
        float: right;
        margin: 5px 5px 0;
    }
}

.active {
    background-color: #f2f2f2;
}
.pagination-container {
    height: auto;
}
::v-deep {
	.common-form{
		.el-textarea{
			span{
				background-color: transparent;
			}
		}
	}
}
.remark_class{
    position: relative;
    ::v-deep .el-form-item__label{
        width: 88px !important;
    }
    .first_enter{
        position: absolute;
        left: -16px;
        top: 32%;
        transform: translateY(-50%);
    }
}
</style>
<style lang="scss">
.tooltip-popper{
    width: 260px;
	background: #2B2F33;
	opacity: 0.9 !important;
	border-radius: 8px !important;
	padding: 10px 10px;
}
</style>
